import { Link, useFetcher } from "@remix-run/react";
import Badge from "antd-mobile/es/components/badge";
import List from "antd-mobile/es/components/list";
import Stepper from "antd-mobile/es/components/stepper";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import Tag from "antd-mobile/es/components/tag";
import { memo, useCallback, useRef } from "react";
import Forked from "~/modules/recipe/components/Forked";
import { closeConfirmModal, confirmModal } from "~/modules/recipe/components/confirmModal";
import { useAppTenantRoute } from "~/utils/data/useRecipeRouteData";
import clsx from "~/utils/shared/ClassesUtils";
import { useCookRecipeSelectorContext } from "../context/cookRecipeContext";
import { useRecipeSelectorContext } from "../context/recipeContext";
import { useShoppingListSelectorContext } from "../context/shoppingListContext";

function RecipeListItem({ index, style, data }) {
  const { data: items, onClick, type } = data;
  const forkedFetcher = useFetcher();
  const recipeItemRef = useRef(null);
  const handleWholeRecipeToShoppingList = useShoppingListSelectorContext((state) => state.handleWholeRecipeToShoppingList);
  const setThisCollectionId = useCookRecipeSelectorContext((state) => state.setThisCollectionId);
  const setThisRecipeId = useCookRecipeSelectorContext((state) => state.setThisRecipeId);
  const lookupRecipes = useCookRecipeSelectorContext((state) => state.lookupRecipes);

  const changeServings = useRecipeSelectorContext((state) => state.changeServings);

  const handleClick = (urlId, urlKey) => {
    if (onClick) {
      onClick(urlId, urlKey);
    }
  };
  const item = items[index];

  const handleForkRecipe = useCallback(async () => {
    closeConfirmModal();
    if (item.recipeId) {
      // setCurrentForked(newState);
      const form = new FormData();
      form.set("action", `unfork-recipe`);
      form.set("recipeId", item.recipeId);
      await forkedFetcher.submit(form, {
        method: "post",
        action: "/api/recipe/fork",
      });
    }
  }, [forkedFetcher, item.recipeId]);

  if (!item) {
    return null;
  }

  const handleAddToShoppingList = () => {
    lookupRecipes([item.recipeId]);
    handleWholeRecipeToShoppingList(item.recipeId);
    setThisRecipeId(item.recipeId);
    setThisCollectionId(null);
    changeServings(parseInt(item.servings));
  };

  const handleUnFork = () => {
    confirmModal({
      header: (
        <div
          className={clsx("flex h-20 w-20 min-w-full items-end rounded-full bg-cover bg-center text-white")}
          style={{ backgroundImage: `url("${item.imageFilename}?class=w40h40")` }}
        ></div>
      ),
      title: (
        <div className="py-4">
          <div>Un-Fork</div>
          <div>{item.name}</div>
        </div>
      ),
      // content: (
      //   <div className="mt-3 text-center sm:mt-5">
      //     <h3 className="text-base font-semibold leading-6 text-gray-900">{`${name}`}</h3>
      //     <div className="mt-2">
      //       <p className="text-sm text-gray-500">{`Are you sure you want to un-fork ${item.name || "this recipe"}?`}</p>
      //       <p className="text-sm text-gray-500">{`This will remove it from your forked recipes.`}</p>
      //     </div>
      //   </div>
      // ),
      actions: [
        [
          {
            key: "cancel",
            text: "Cancel",
            className: "adm-button-default",
            onClick: closeConfirmModal,
          },
          {
            key: "unfork",
            text: "Un-Fork",
            className: "button-fill-important",
            danger: true,
            onClick: handleForkRecipe,
          },
        ],
      ],
    });
  };
  const url = new URL(item.url);
  const hostname = url.hostname;
  const image = (
    <div
      className={clsx("flex h-10 w-10 min-w-full items-end rounded-lg bg-cover bg-center text-white")}
      style={{ backgroundImage: `url("${item.imageFilename}?class=w40h40")` }}
    ></div>
  );
  let leftActions = [
    {
      key: "pin",
      text: "Un-Fork",
      color: "danger",
      onClick: handleUnFork,
    },
  ];
  let rightActions = [
    {
      key: "shop",
      text: "Add to Shopping List",
      color: "warning",
      onClick: handleAddToShoppingList,
    },
    // {
    //   key: "plan",
    //   text: "Add to Calendar",
    //   color: "primary",
    // },
  ];

  const handleActionsRevealed = (direction: "left" | "right") => {
    if (direction === "left") {
      if (leftActions.length === 1) {
        leftActions[0].onClick && leftActions[0].onClick();
      }
    } else {
      if (rightActions.length === 1) {
        rightActions[0].onClick && rightActions[0].onClick();
      }
    }
    recipeItemRef.current?.close();
  };

  if (type === "shopping-item") {
    leftActions = [
      {
        key: "plan",
        text: "Plan",
        color: "primary",
      },
    ];
    rightActions = [
      {
        key: "shop",
        text: "Add to Shopping List",
        color: "warning",
      },
    ];
  }

  const forkedIcon = (
    <Forked
      id={item.id}
      forked={true}
      editable={false}
      name={item.name}
      type={"recipe"}
      image={`${item.imageFilename}`}
      buttonProps={{ className: "h-full w-full" }}
    />
  );
  const listItemPrefix = (
    <Badge className="h-1/2 w-1/2 rounded-full" bordered wrapperClassName="p-0 flex" content={forkedIcon} style={{ "--color": "var(--adm-color-white)" }}>
      {image}
    </Badge>
  );
  const innerContent = (
    <Link to={`${item.baseUrl}${item.urlId}/${item.urlKey}`} title={item.name}>
      <List.Item
        className="!flex h-full break-all"
        // onClick={() => handleClick(item.urlId, item.urlKey)}
        description={<div className="line-clamp-1 text-sm">{hostname}</div>}
        prefix={item.free ? image : listItemPrefix}
        extra={item.free && <Tag color="#108ee9">FREE</Tag>}
      >
        {/* <Ellipsis direction="end" rows={2} content={item.name} /> */}
        <div className="line-clamp-2 break-normal text-base font-semibold">{item.name}</div>
        {/* <div className="text-sm line-clamp-1">{hostname}</div> */}
      </List.Item>
    </Link>
  );

  if (item.free) {
    return innerContent;
  }

  return (
    <SwipeAction
      className="full-height"
      style={style}
      leftActions={leftActions}
      rightActions={rightActions}
      onActionsReveal={handleActionsRevealed}
      ref={recipeItemRef}
    >
      {innerContent}
    </SwipeAction>
  );
}

export default memo(RecipeListItem);

export function NonVirtualRecipeListItem({ item, leftActions, rightActions, disabled = false, onServingsChange, showUrl = false, isLink = false, onClick }) {
  const recipeItemRef = useRef(null);
  const appTenantRoute = useAppTenantRoute();

  const url = new URL(item.url);

  const image = (
    <div
      className={clsx("flex h-10 w-10 min-w-full items-end rounded-lg bg-cover bg-center text-white")}
      style={{ backgroundImage: `url("${item.imageFilename}?class=w40h40")` }}
    ></div>
  );

  const listItemPrefix = (
    <Badge className="h-1/2 w-1/2 rounded-full" bordered wrapperClassName="p-0 flex" style={{ "--color": "var(--adm-color-white)" }}>
      {image}
    </Badge>
  );

  const handleActionsRevealed = (direction: "left" | "right") => {
    if (direction === "left") {
      if (leftActions.length === 1) {
        leftActions[0].onClick && leftActions[0].onClick();
      }
    } else {
      if (rightActions.length === 1) {
        rightActions[0].onClick && rightActions[0].onClick();
      }
    }
    recipeItemRef.current?.close();
  };

  const handleServingsChange = useCallback(
    (value) => {
      onServingsChange(item.id, value);
    },
    [item]
  );
  const content = (
    <List.Item
      className={clsx("!flex h-full break-all", disabled && "opacity-50")}
      onClick={onClick}
      clickable={false}
      // description={<div className="line-clamp-1 text-sm">{item.url}</div>}
      prefix={listItemPrefix}
      description={showUrl && <div className="line-clamp-1 text-sm">{url.hostname}</div>}
      extra={
        onServingsChange && (
          <div className="flex w-32 justify-center">
            <Stepper
              className="large-stepper w-full"
              defaultValue={parseInt(item.servings) ?? 1}
              min={1}
              max={1000}
              onChange={handleServingsChange}
              aria-label={"Servings Adjustment"}
            // onChange={(value) => {
            //   console.log("change servings: ", value);
            // }}
            />
          </div>
        )
      }
    >
      {/* <Ellipsis direction="end" rows={2} content={item.name} /> */}
      <div className={clsx("line-clamp-2 break-normal text-base font-semibold", disabled && "line-through")}>{item.name}</div>
      {/* <div className="text-sm line-clamp-1">{hostname}</div> */}
    </List.Item>
  );
  if (isLink) {
    return (
      <Link to={`${appTenantRoute?.pathname || ""}/recipe/${item.urlId}/${item.urlKey}`} title={item.name}>
        {content}
      </Link>
    );
  }

  return (
    <SwipeAction className="h-full" leftActions={leftActions} rightActions={rightActions} onActionsReveal={handleActionsRevealed} ref={recipeItemRef}>
      {content}
    </SwipeAction>
  );
}
